//!* Vars and mixins
	$body-color: #489283;
	$body-bg: #ffffff;

	$white: #fff;
	$gray-text: #6f6f6e;
	$gray-bg: #f5f5f5;
	$lime: #c3d241;
	$green: #489283;
	$red: #ef5350;
	$gradient: linear-gradient(to right, #c5d434 0%, #39888d 50%, #39888d 100%);

	$ff-default: 'Avenir LT Std', sans-serif;
	
	$fs-h1: 2.6rem;
	$fs-h2: 2.2rem;
	$fs-h3: 1.8rem;

	$fs-default: 18px;

	$shadow: none;
	$radius: 12px;

	$sp-30: 30px;
	$sp-25: 25px;
	$sp-20: 20px;
	$sp-15: 15px;
	$sp-10: 10px;
	

//!* Resets - defaults
	:root {
		font-size: $fs-default;
		--sc-pd-lg: 7vw;
		--sc-pd-sm: 5vw;
		--hd-pd: 4vw;

		@media (max-width : 1399px) {
			// font-size: $fs-default - 1;
			--sc-pd-lg: 8vw;
			--sc-pd-sm: 6vw;
			--hd-pd: 5vw;
		}
		
		@media (max-width : 991px) {
			--sc-pd-lg: 9vw;
			--sc-pd-sm: 7vw;
			--hd-pd: 6vw;
		}

		@media (max-width : 767px) {
			// font-size: $fs-default - 2;
			font-size: $fs-default - 1;
			--sc-pd-lg: 12vw;
			--sc-pd-sm: 10vw;
		}
        
        @media (max-width : 575px) {
			font-size: $fs-default - 1;
            --sc-pd-lg: 14vw;
            --sc-pd-sm: 12vw;
		}
		
		@media (max-width : 374px) {
			font-size: $fs-default - 2;
		}
	}

	::selection {
		color: $white;
		background: $blue;
	}

	*, *:before, *:after {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		font-feature-settings: 'liga';
	}

	html { background: $body-bg; }

	body {
		background: $body-bg;
		color: $body-color;
		font-family: $ff-default;
		font-weight: 300;
	}

	img {
		user-select: none;
		pointer-events: none;
	}

	p {
		margin-bottom: 1.66em;
		line-height: 1.66em;

		&:last-child { margin-bottom: 0; }

		a {
			color: currentColor !important;
		}
	}

	strong,
	b,
	.text-bold {
		font-weight: 900;
	}

	button {
		outline: none !important;
		transition: color 300ms ease, background 300ms ease, border 300ms ease, transform 150ms linear, box-shadow 300ms ease;
	}

	a {
		transition: color 300ms ease, background 300ms ease, border 300ms ease, transform 150ms linear, box-shadow 300ms ease;
		text-decoration: none !important;
		color: $body-color;
	}

	svg {
		display: block;
		fill: currentColor;
	}

	#icon {
		display: block;
		position: absolute;
		left: -9999em;
	}

	h1, .h1, h2, .h2, h3, .h3 {
		margin-top: 1.5em;
		margin-bottom: 1em;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: -0.01em;
		line-height: 1.075em;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}

	h1, .h1 {
		font-size: $fs-h1;

		@media (max-width : 1399px) {
			font-size: $fs-h1 - 0.15;
		}
		
		@media (max-width : 991px) {
			font-size: $fs-h1 - 0.45;
		}
		
		@media (max-width : 767px) {
			font-size: $fs-h1 - 0.6;
		}

		@media (max-width : 575px) {
			font-size: $fs-h1 - 0.8;
		}
	} 

	h2, .h2 {
		font-size: $fs-h2;

		@media (max-width : 1399px) {
			font-size: $fs-h2 - 0.15;
		}
		
		@media (max-width : 991px) {
			font-size: $fs-h2 - 0.25;
		}
		
		@media (max-width : 767px) {
			font-size: $fs-h2 - 0.35;
		}
	} 

	h3, .h3 {
		font-size: $fs-h3;

		@media (max-width : 1399px) {
			font-size: $fs-h3 - 0.25;
		}
		
		// @media (max-width : 991px) {
		// 	font-size: $fs-h3 - 0.35;
		// }

		// @media (max-width : 767px) {
		// 	font-size: $fs-h3 - 0.45;
		// }

		// @media (max-width : 575px) {
		// 	font-size: $fs-h3 - 0.5;
		// }
	} 

	.container {
		@media (min-width : 1400px) {
			max-width: 1320px;
		}

		@media (max-width : 575px) {
			padding-left: 6vw;
			padding-right: 6vw;
		}
	}