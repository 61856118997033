.header {
	.container {
		@media (max-width : 575px) {
			padding-left: 3vw;
			padding-right: 0;
		}
	}
}

.header--brand {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	padding: 1rem 0;
	white-space: nowrap;
	font-size: 1.1rem;
	line-height: 1.3rem;
	font-style: italic;
	color: $green !important;

	@media (max-width : 1399px) {
		font-size: 1.05rem;
		line-height: 1.2rem;
	}

	@media (max-width : 767px) {
		padding: .75rem 0;
		flex:  1 1 auto;
	}

	@media (min-width : 768px) {
		transform: translateX(-1.8rem);
	}

	@media (max-width : 575px) {
		padding: .5rem 0;
	}

	div {
		flex: 0 0 150px;

		@media (max-width : 1399px) {
			flex: 0 0 145px;
		}

		@media (max-width : 991px) {
			flex: 0 0 140px;
		}

		@media (max-width : 767px) {
			flex: 0 0 130px;
		}

		@media (max-width : 575px) {
			flex: 0 0 100px;
		}
	}

	span {
		display: block;
		padding-left: 1.5rem;
		margin-left: 1.5rem;
		border-left: 2px solid $green;

		@media (max-width : 1399px) {
			padding-left: 1.3rem;
			margin-left: 1.3rem;
		}
	
		@media (max-width : 1199px) {
			display: none;
		}
	}
}

.header--nav--items {
	padding: 1rem 0 1.5rem;

	@media (min-width : 768px) {
		margin-right: 1rem;
		padding: 0;
	}

	> li {
		@media (min-width : 768px) {
			display: flex;
		}
		
		> a {
			display: flex;
			align-items: center;
			white-space: nowrap;
			color: $green !important;
			font-weight: normal;
			padding: 0 1.5rem;

			@media (max-width : 1399px) {
				padding: 0 1.35rem;
			}

			@media (max-width : 1199px) {
				padding: 0 1.25rem;
			}

			@media (max-width : 991px) {
				padding: 0 .9rem;
			}

			@media (max-width : 767px) {
				font-weight: normal;
				font-size: 1.25rem;
				padding: .75rem 1.5rem .5rem;
			}

			@media (max-width : 575px) {
				font-size: 1.15rem;
			}

			@media (hover : hover) {
				&:hover {
					color: $lime !important;
				}
			}
		}

		&.is--active {
			background: $gray-bg;

			@media (max-width : 767px) {
				border-radius: $radius;
			}

			> a {
				color: $green !important;
			}
		}
	}
}

.header--right {
	padding: 0 1rem;
	background: $green;
	color: $white;

	@media (max-width : 991px) {
		padding: 0;
	}
	
	@media (max-width : 767px) {
		margin-left: 1rem;
	}

	@media (max-width : 575px) {
		margin-left: .66rem;
		padding-right: 3vw;
	}
}

.header--social {
	a {
		display: block;
		font-size: 22px;
		padding: .3rem;
		color: $white !important;

		@media (hover : hover) {
			&:hover {
				color: $lime !important;
			}
		}
	}
}

.header--lang {
	border-left: 2px solid $lime;
	margin-left: 1rem;
	padding-left: 1rem;

	@media (max-width : 1199px) {
		margin-left: .75rem;
		padding-left: .75rem;
	}
	
	@media (max-width : 991px) {
		margin-left: 0;
		padding-left: 0;
		border: none;
	}

	@media (hover : hover) {
		&:hover {
			> button {
				color: $lime !important;
			}

			> .dropdown-menu {
				display: block;
			}
		}
	}

	> button {
		display: flex;
		align-items: center;
		padding: .3rem;
		border: none;
		background: none;
		outline: none;
		color: $white;
		font-weight: 900;
		font-size: .9rem;

		@media (max-width : 991px) {
			padding: 0 1.15rem;
		}

		@media (max-width : 575px) {
			padding: 0 .75rem;
		}

		.icon {
			margin-left: .25rem;

			@media (max-width : 575px) {
				font-size: .85rem;
			}
		}
	}

	.dropdown-menu {
		top: 100% !important;
		left: auto !important;
		right: 0 !important;
		margin: 0;
		border: none;
		border-radius: 0;
		box-shadow: 0 0 10px rgba($black, .16);
		border-radius: $radius;
		background: $white;
		padding: .75rem 0;
		user-select: none;
		min-width: 1rem;

		@media (max-width : 991px) {
			right: 20% !important;
			top: 75% !important;
		}

		a {
			display: block;
			color: $green !important;
			font-weight: 400;
			padding: .66rem 1.75rem .66rem 1.33rem;
			line-height: 1;

			@media (max-width : 767px) {
				padding: .8rem 3rem .8rem 1.75rem;
			}

			@media (hover : hover) {
				&:hover {
					color: $lime !important;
				}
			}
		}
	}
}

.header--actions {
	button {
		border: none;
		background: none;
		color: currentColor;
		padding: 0 1rem;

		@media (max-width : 575px) {
			padding: 0 .66rem;
		}

		i {
			display: block;
			width: 1.75rem;
			height: 2px;
			background: $green;
			margin: auto;

			&:nth-child(2) {
				margin: 7px auto;
			}
		}

		&:not(.collapsed) {
			transform: rotate(90deg);

			i {
				&:nth-child(1) {
					transform: translate3d(0,9px,0) rotate(-45deg);
				}

				&:nth-child(2) {
					transform: scale(0);
				}

				&:nth-child(3) {
					transform: translate3d(0,-9px,0) rotate(45deg);
				}
			}
		}
	}

	a {
		display: flex;
		align-items: center;
		padding: 0 1rem;
		color: $green !important;
		font-size: 28px;

		@media (max-width : 575px) {
			font-size: 26px;
			padding: 0 .66rem;
		}
	}
}