.form-group {
	border-radius: 25px;
	padding: 1px;
	background: $green;
	background: $gradient;
	margin-bottom: 1.25rem;
	position: relative;
}

.form-control {
	font-family: $ff-default;
	font-weight: 400;
	border: none;
	font-size: 1rem;
	border-radius: 25px;
	appearance: none;
	padding: .75rem 1.25rem .55rem;
	background: $white !important;
	color: $green !important;
	position: relative;
	transition: color 300ms ease, background 5000s ease, box-shadow 300ms ease;

	&::placeholder {
		font-weight: 500;
		color: $green;
	}

	&:focus {
		box-shadow: 0 0 0 1px $green, 0 0 0 0.3rem rgba($green, .25) !important;
	}
}

textarea.form-control {
	resize: none;
	min-height: 90px;
}

:-webkit-autofill,
:-webkit-autofill:hover {
	-webkit-text-fill-color: $green;
	box-shadow: 0 0 0px 1000px $body-bg inset !important;
	transition: color 300ms ease, background 5000s ease, box-shadow 300ms ease;
}

:-webkit-autofill:focus {
	-webkit-text-fill-color: $green;
	box-shadow: 0 0 0px 1000px $body-bg inset, 0 0 0 1px $green, 0 0 0 0.3rem rgba($green, .25) !important;
	transition: color 300ms ease, background 5000s ease, box-shadow 300ms ease;
}

.was-validated {
	.form-control:invalid {
		color: $red !important;
		box-shadow: 0 0 0 1px $red !important;

		&:focus {
			box-shadow: 0 0 0 1px $red, 0 0 0 0.3rem rgba($red, .25) !important;
		}

		&::placeholder {
			font-weight: 500;
			color: $red !important;
		}
	}
}