.footer {
	position: relative;
	z-index: 1;
	color: $white;
	font-weight: 400;

	@media (min-width : 768px) {
		padding: 2rem 0;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		inset: 0 calc((100vw - 1290px) / 2) 0 0;
		background: $green;
		border-radius: 0 250px 0 0;
		z-index: -1;

		@media (max-width : 1399px) {
			inset: 0 calc((100vw - 1110px) / 2) 0 0;
		}

		@media (max-width : 1199px) {
			border-radius: 0 150px 0 0;
			inset: 0 calc((100vw - 930px) / 2) 0 0;
		}

		@media (max-width : 991px) {
			border-radius: 0 100px 0 0;
			inset: 0 calc((100vw - 690px) / 2) 0 0;
		}

		@media (max-width : 767px) {
			inset: 0 calc((100vw - 510px) / 2) 0 0;
		}

		@media (max-width : 575px) {
			border-radius: 0 75px 0 0;
			inset: 0 6vw 0 0;
		}
	}

	p {
		line-height: 1.5;
	}

	a {
		color: $white !important;

		@media (hover : hover) {
			&:hover {
				color: $lime !important;
			}
		}
	}
}

.footer--brand {
	white-space: nowrap;
	font-style: italic;
	margin-right: 2.5rem;

	@media (max-width : 767px) {
		margin: 0;
	}

	.mr {
		width: 150px;
		margin-bottom: 1.75rem;

		@media (max-width : 991px) {
			width: 135px;
		}
		
		@media (max-width : 767px) {
			margin: 0 1.25em 0 0;
		}
		
		@media (max-width : 575px) {
			width: 115px;
		}
	}

	p {
		@media (max-width : 767px) {
			line-height: 1.25;
			margin-bottom: -0.35em !important;
		}
	}
}

.footer--mail {
	display: block;
	font-size: 1.25em;
	margin-bottom: 1.25rem;

	@media (max-width : 991px) {
		font-size: 1.15em;
	}
	
	@media (max-width : 767px) {
		margin: 2rem 0;
	}
}

.footer--p {
	@media (max-width : 991px) {
		border-left: 2px solid $white;
		padding-left: 1.25rem;
	}

	& + .footer--p {
		@media (min-width : 992px) {
			border-left: 2px solid $white;
			margin-left: 1.25rem;
			padding: 0 0 0 1.25rem;
		}

		@media (max-width : 991px) {
			padding-top: 1em;
		}
	}
}

.footer--social {
	margin: 1rem 0;
	font-size: 26px;

	@media (max-width : 991px) {
		margin: 1.25rem 0;
	}

	@media (max-width : 767px) {
		margin: 2rem 0;
	}

	li + li {
		margin-left: .66rem;
	}
}

.footer--small {
	font-size: 85%;
}