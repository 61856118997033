.icon {
	display: block;
	width: 1em;
	height: 1em;
	transform: translate3d(0,0,0);

	&.icon--flip {
		transform: translate3d(0,0,0) rotate(180deg);
	}
}

.mr {
	display: block;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.mr--fit {
		position: absolute;
		z-index: 1;
		max-height: 100%;
	    max-width: 100%;
	    width: auto;
	    height: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
		margin: auto;
		transform: translate3d(0,0,0);
	}

	.mr--full {
		position: absolute;
		z-index: 1;
		top: 0;
	    left: 0;
	    width: 100%;
		height: 100%;
		transform: translate3d(0,0,0);
	}

	img.mr--full {
		object-fit: cover;
	}

	&:before {
		content: '';
		display: block;
	}

	&.mr--16by9:before { padding-bottom: 56.3%; }
	&.mr--4by3:before { padding-bottom: 75%; }
	&.mr--3by2:before { padding-bottom: 66%; }
	&.mr--1by1:before { padding-bottom: 100%; }
	&.mr--2by1:before { padding-bottom: 50%; }
	&.mr--brand:before { padding-bottom: 63%; }

	&.mr--map:before {
		padding-bottom: 39%;

		@media (max-width : 767px) {
			padding-bottom: 56%;
		}

		@media (max-width : 575px) {
			padding-bottom: 66%;
		}
	}

	&.mr--banner:before {
		padding-bottom: 50%;

		@media (max-width : 575px) {
			padding-bottom: 150%;
		}
	}
}

.alert {
	position: fixed;
	top: 2.08vw;
	left: 50%;
	padding: $sp-30 ($sp-25 * 2);
	width: 540px;
	font-weight: bold;
	line-height: 1.35em;
	text-align: center;
	color: $white;
	border-radius: 9px;
	transform: translate(-50%,-200%);
	user-select: none;
	z-index: 199;
	transition: transform 300ms ease, box-shadow 300ms ease, background 300ms ease;
	box-shadow: none;
	will-change: transform, box-shadow;
	pointer-events: none;

	@media (max-width : 991px) {
		top: 3.04vh;
	}

	@media (max-width : 575px) {
		padding: $sp-20 ($sp-20 * 2);
		top: 4vh;
		width: 84%;
	}

	&.is--visible {
		transform: translate(-50%,0);
		box-shadow: 0 0 75px rgba($black, .5);
		pointer-events: auto;

		& + .alert--shadow {
			opacity: 1;
		}
	}

	&.alert--success { background: $green; }
	&.alert--error { background: $red; }
	&.alert--info { background: $lime; }
}

.btn {
	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	padding: .7rem 3rem .5rem;
	user-select: none;
	font-size: .95srem;
	font-weight: 600;
	line-height: 1.5em;
	text-transform: uppercase;
	border: none;
	border-radius: $radius * 20;
	background: $green;
	background: $gradient;
	color: $white !important;
	overflow: hidden;
	transition: transform 100ms ease, color 300ms ease, border 300ms ease;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 101%;
		height: 100%;
		background: $lime;
		border-radius: $radius * 20;
		transform: translate3d(-105%,0,0);
		transition: transform 500ms ease;
	}

	&:active {
		transform: translate3d(0,0,0) scale(.9);
	}

	@media (hover: hover) {
		&:hover {
			&:before {
				transform: translate3d(0,0,0);
			}
		}
	}
}

.swiper-arrow {
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12%;
	height: 100%;
	color: $white;
	font-size: 3rem;
	cursor: pointer;
	z-index: 2;
	transition: color 300ms ease, opacity 300ms ease;

	@media (max-width : 1199px) {
		font-size: 2.5rem;
	}

	@media (max-width : 991px) {
		display: none;
	}

	@media (hover: hover) {
		opacity: .33;

		&:hover {
			color: $green !important;
		}
	}
}

.swiper:hover {
	@media (hover: hover) {
		.swiper-arrow {
			opacity: 1;
		}
	}
}

.swiper-arrow-prev {
	left: 0;
}

.swiper-arrow-next {
	right: 0;
}

.swiper-dots {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	bottom: $sp-30;
	width: 100%;
	z-index: 1;
	pointer-events: none;
	transition: background 300ms ease;

	@media (min-width : 992px) {
		display: none;
	}
}

.swiper-dot {
	display: block;
	width: 9px;
	height: 9px;
	margin: 0 4px;
	border-radius: 50%;
	background: $white;
	pointer-events: auto;
}

.swiper-dot_active {
	background: $green;
}

.fancybox__caption {
	text-align: center;
	line-height: 1.5;

	strong {
		display: block;
		font-size: 1.1em;
		padding-bottom: .1em;
	}
}