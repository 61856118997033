.sc--pd_lg {
	padding-top: var(--sc-pd-lg);
	padding-bottom: var(--sc-pd-lg);
}

.sc--pd_sm {
	padding-top: var(--sc-pd-sm);
	padding-bottom: var(--sc-pd-sm);
}

// index
.sc--index {
	h2 {
		color: $gray-text;
	}

	.btn {
		@media (max-width : 1199px) and (min-width : 992px) {
			margin-bottom: 2.5rem;
		}
	}

	.row {
		margin: 0 -1.5rem;

		> * {
			padding: 0 1.5rem;
		}
	}

	.mr {
		@media (max-width : 991px) {
			width: 75% !important;

			&:first-child {
				margin: 0 0 0 auto;
			}

			&:last-child {
				margin: 2.5rem auto 0 0 !important;
			}
		}

		@media (max-width : 767px) {
			width: 87% !important;
		}

		.mr--full {
			border-radius: $radius;
		}

		& + .mr {
			margin: 2.5rem 0 0 auto;
			width: 81%;
		}
	}

	.gradient {
		margin-bottom: 2.5rem;
		height: .5rem;
		border-radius: 50px 0 0 50px;
		background: $green;
		background: $gradient;
		margin-right: calc(0px - ((100vw - 1290px) / 2));

		@media (max-width : 1399px) {
			margin-right: calc(0px - ((100vw - 1110px) / 2));
		}

		@media (max-width : 1199px) {
			margin-right: calc(0px - ((100vw - 930px) / 2));
		}

		@media (max-width : 991px) {
			margin-right: calc(0px - ((100vw - 690px) / 2));
		}

		@media (max-width : 767px) {
			margin-right: calc(0px - ((100vw - 510px) / 2));
		}

		@media (max-width : 575px) {
			margin-right: -6vw;
		}
	}
}

.sc--products-link {
	background: $lime;

	a {
		padding: .5em 1em .3em;
		border-radius: 100px;
		border: 2px solid $white;
		color: $white !important;

		&:before {
			content: '';
			display: block;
			width: 2em;
			height: 4px;
			position: absolute;
			inset: 100% 0 0 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			background: $lime;
		}
	}

	.icon {
		position: absolute;
		inset: 100% 0 0 50%;
		transform: translate(-50%,-40%) rotate(-90deg);
		padding: 0 .4em;
		width: 2em;
		z-index: 2;
		transition: transform 300ms ease;

		@media (max-width : 767px) {
			height: 1.25em;
		}
	}

	@media (hover : hover) {
		&:hover {
			.icon {
				transform: translate(-50%,-25%) rotate(-90deg);
			}
		}
	}
}

// about
.sc--about_1 {
	h2 {
		font-weight: 900;
		color: $gray-text;
	}

	hr {
		margin: 1.66rem 0 !important;
		width: 150%;
		
		@media (max-width : 991px) {
			width: 66%;
			transform: translateX(calc(0px - ((100vw - 690px) / 2)));
		}

		@media (max-width : 767px) {
			transform: translateX(calc(0px - ((100vw - 510px) / 2)));
		}

		@media (max-width : 575px) {
			width: 75%;
			transform: translateX(-6vw);
		}
	}

	.mr {
		margin-right: calc(0px - ((100vw - 1290px) / 2));

		@media (max-width : 1399px) {
			margin-right: calc(0px - ((100vw - 1110px) / 2));
		}

		@media (max-width : 1199px) {
			margin-right: calc(0px - ((100vw - 930px) / 2));
		}

		@media (max-width : 991px) {
			margin-right: calc(0px - ((100vw - 690px) / 2));
		}

		@media (max-width : 767px) {
			margin-right: calc(0px - ((100vw - 510px) / 2));
		}

		@media (max-width : 575px) {
			margin-right: -6vw;
		}
	}
}

.sc--about_2 {
	h3 {
		font-weight: 900;
		color: $gray-text;
	}

	hr {
		@media (min-width : 768px) {
			position: absolute;
			right: 100%;
			top: .5rem;
			width: 16vw;
		}

		@media (max-width : 1680px) {
			width: 12vw;
		}

		@media (max-width : 767px) {
			width: 75%;
			margin: 1.66rem 0 !important;
			transform: translateX(calc(0px - ((100vw - 510px) / 2)));
		}

		@media (max-width : 575px) {
			transform: translateX(-6vw);
		}
	}

	.mr {
		@media (max-width : 767px) {
			margin-left: calc(0px - ((100vw - 510px) / 2));
		}

		@media (max-width : 575px) {
			margin-left: -6vw;
		}
	}
}

.sc--about_3 {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		display: block;
		position: absolute;
		inset: 50% 0 0 0;
		inset: calc(50% - 2.33rem) 0 0 0;
		z-index: -1;
		background: $lime;

		@media (max-width : 1199px) {
			inset: calc(50% - 2.08rem) 0 0 0;
		}
	}

	.swiper {
		overflow: visible;
	}

	.swiper-wrapper {
		margin-bottom: 1.66rem;
	}

	.swiper-arrow {
		display: block !important;
		width: auto;
		position: static !important;
		opacity: 1 !important;
		color: currentColor !important;

		@media (hover : hover) {
			&:hover {
				color: $white !important;
			}
		}
	}

	.swiper-dots {
		padding: 0 1em;
		width: auto !important;
		position: static !important;
		display: block !important;
		font-weight: 500;
		font-size: 1.3em;
		font-variant-numeric: tabular-nums;
		user-select: none;

		@media (max-width : 575px) {
			font-size: 1.1rem;
		}
	}
}

// products
.sc--products_1 {
	.h2 {
		font-weight: 900;
		color: $gray-text;
		white-space: nowrap;
	}

	hr {
		margin: .6rem 2rem;
		height: .5rem;
		background: $green;
		background: $gradient;
		opacity: 1;
		min-width: 6rem;
		border-radius: 50px;

		@media (max-width : 767px) {
			margin: 1.66rem 0;
			width: 66%;
			transform: translateX(calc(0px - ((100vw - 510px) / 2)));
		}

		@media (max-width : 575px) {
			width: 75%;
			transform: translateX(-6vw);
		}
	}
}

.sc--products_2 {
	ul {
		li + li {
			&:before {
				content: '';
				margin: calc(var(--sc-pd-sm) / 1.5) 0;
				display: block;
				background: $green;
				background: $gradient;
				height: 1px;
				border-radius: 50px;
			}
		}
	}
}

.product--i {
	display: flex;
	position: relative;

	@media (max-width : 767px) {
		flex-direction: column;
	}

	.product--i_image {
		align-self: flex-start;
		flex: 0 0 40%;
		border-radius: $radius;
		overflow: hidden;

		@media (max-width : 991px) {
			flex: 0 0 50%;
		}

		@media (max-width : 767px) {
			width: 100%;
			flex: 0 0 auto;
		}

		&:before {
			padding-bottom: 50%;

			@media (max-width : 991px) and (min-width : 768px), (max-width : 575px) {
				padding-bottom: 56.3%;
			}
		}
	}

	.product--i_content {
		padding-left: 2rem;
		align-self: center;

		@media (max-width : 767px) {
			align-self: flex-start;
			padding: 1.66rem 0 0;
		}

		h3 {
			font-weight: 900;
			color: $gray-text;
			margin-bottom: .9rem;
			line-height: 1em;
		}

		p {
			margin-bottom: 1.25rem;
			// line-height: 1.25rem;
		}
	}

	.product--i_isset {
		display: block;
		position: absolute;
		inset: 0;
		z-index: 19;
	}

	.btn {
		position: relative;
		padding: 0.6rem 1.5rem 0.4rem;
		z-index: 20;
		color: $green !important;
		background: none !important;
		border: 2px solid $green;
		font-size: 93%;

		&:before {
			background: $green !important;
		}
		
		@media (max-width : 575px) {
			font-size: 87%;
		}

		@media (hover : hover) {
			&:hover {
				color: $white !important;
				border-color: $green !important;
			}
		}
	}
}

// contact
.sc--contact {
	h1 {
		color: $gray-text;
		font-weight: 900;

		br {
			@media (min-width : 992px) {
				display: none;
			}
		}
	}

	hr {
		margin: .45rem 2rem;
		opacity: 1;
		height: .5rem;
		border: none;
		background: $green;
		background: $gradient;
		border-radius: 50px;
		flex-shrink: 0;
		min-width: 3rem;

		@media (max-width : 575px) {
			margin-right: 0;
		}
	}

	p {
		white-space: nowrap;
		flex-shrink: 0;
		color: $gray-text;

		@media (max-width : 575px) {
			padding-top: 1.5rem;
		}
	}

	ul {
		padding-bottom: .4rem;
		margin-left: 3rem;
		flex-shrink: 0;

		@media (max-width : 991px) {
			margin-left: 2rem;
		}

		@media (max-width : 767px) {
			margin: 0 !important;
			padding: 0 !important;
		}

		li + li {
			margin-left: .66rem;
		}

		a {
			display: block;
			font-size: 32px;
			color: $green !important;

			@media (hover : hover) {
				&:hover {
					color: $lime !important;
				}
			}
		}
	}

	.btn {
		background: $green !important;

		@media (max-width : 767px) {
			width: 100%;
		}
	}
}

.sc--map {
	background: $lime;

	p {
		font-weight: 500;
		color: $white;
		padding: 1.5rem 0 1.3rem;
	}
}