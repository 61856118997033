#app {
	width: 100%;
	overflow: hidden;
}

.index {
	.footer {
		background: $lime;
	}
}

.about {
	hr {
		height: .5rem;
		border-radius: 50px;
		background: $green;
		background: $gradient;
		border: none;
		margin: 0;
		opacity: 100;
	}

	.footer {
		background: $lime;
	}

	.mr--full {
		border-radius: $radius;
	}
}

.contact {
	.header--nav--items {
		@media (max-width : 767px) {
			padding-bottom: 0 !important;
		}
	}

	.footer {
		border-top: 1.5rem solid $lime;
		background: $lime;
	}
}